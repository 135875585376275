import $ from 'jquery'
import Util from './util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): formajax.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const FormAjax = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'formajax'
  const VERSION = '4.1.3'
  const DATA_KEY = 'bs.formajax'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Events = {
    click: 'click',
    change: 'change',
    selectInited: 'select2:inited',
    filterApply: 'filter',
    LOCAL_BLOCK_SHOWED: 'local.showed',
    svgReinit: 'reinit.bs.oneci',
    SELECT_OPEN: 'select2:open',
    SELECT_OPENING: 'select2:opening',
    SELECT_LOADED: 'select2:results:all',
    SELECT_SELECTED: 'select2:select',
    FORM_SUCCESS: 'success'
  }

  const Selector = {
    formAjax: '.form-ajax',
    inputSelect: '.inputselect',
    searchField: '.select2-search__field',
    resetFilter: '.filter__delete',
    resetFilterSelect: '.reset',
    formControl: '.form-control, .custom-control-label, .inputselect',
    submitButton: 'button[name="web_form_submit"]',
    errorModal: '.error-modal',
    modalBody: '.modal-body',
    modalImage: '.modal-image',
    checkboxes: 'input[type="checkbox"]',
    attrs: '.js-attr',
    SVG: '.inline',
    formSwitch: '#formswitch',
    formSection: '.forms_section',
    formsSwitchVariants: '.forms_switch--company, .forms_switch--individual',
    filterSections: '#sections',
    filterCountries: '#country',
    partnerCatalogueBlock: '.js-partners-catalogue_block',
    phoneMaskContainer: '.phone__mask-container',
    phoneMaskSelect: '.phone__mask-select',
    phoneMaskInput: '.phone__mask-input',
    phoneMaskPlaceholder: '.phone__mask-placeholder',
    phoneMaskResult: '.phone__mask-result',
    phoneMaskResults: '#select2-mask-results',
    phoneMaskSearchDropdown: '.select2-search--dropdown',
    phoneMaskSelectedOption: '.select2-results__option--highlighted',
    countryFormSelector: '#form_dropdown_country',
    localBlock: '.localization-shower',
    unsubscribe: '.quiz',
    unsubscribeReason: 'input[name="reason"]:checked',
    unsubscribeButton: '.js-unsubscribe',
    unsubscribeErrorModal: '.unsubscribe-error-modal'
  }

  const FieldsToGTM = {
    companySize: '#form_dropdown_company_size option:selected'
  }

  const Classes = {
    isInvalid: 'is-invalid',
    customControlInput: 'custom-control-input',
    customControlLabel: 'custom-control-label',
    active: 'active',
    reset: 'reset'
  }
  const FormErrors = {
    en: {
      Unexpected: '<h2>Unexpected Error</h2>',
      ErrorFields: '<h2>Sorry!</h2><p>Please, fill out all required fields.</p>',
      Thanx: '<h2>Thanks for your request!</h2><p>We will get in touch with you shortly.</p>',
      NoParams: '<h2>Unsubscribe Error</h2><p>Tough luck! We have tech issues. Please, try again later.</p>',
      NoReason: '<h2>Unsubscribe Error</h2><p>If you don\'t mind, tell us the reason you are leaving, please.</p>',
      NoUser: '<h2>Unsubscribe Error</h2><p>Hmm... It seems to us you have already unsubscribed.</p>',
      Unsubscribed: '<h2>Unsubscribe Success</h2><p>Done. We\'ll be waiting for you to come back!</p>',
      Brochure: '<h2>The brochure is on the way!</h2>'
    },
    tr: {
      Unexpected: '<h2>Unexpected Error</h2>',
      ErrorFields: '<h2>Üzgünüz!</h2><p>Lütfen tüm gerekli alanları doldurunuz.</p>',
      Thanx: '<h2>Talebiniz için teşekkürler!</h2><p>En kısa zamanda sizinle iletişim kuracağız.</p>',
      NoParams: '<h2>Unsubscribe Error</h2><p>Tough luck! We have tech issues. Please, try again later.</p>',
      NoReason: '<h2>Unsubscribe Error</h2><p>If you don\'t mind, tell us the reason you are leaving, please.</p>',
      NoUser: '<h2>Unsubscribe Error</h2><p>Hmm... It seems to us you have already unsubscribed.</p>',
      Unsubscribed: '<h2>Unsubscribe Success</h2><p>Done. We\'ll be waiting for you to come back!</p>',
      Brochure: '<h2>Broşürünüz yolda!</h2>'
    },
    pl: {
      Unexpected: '<h2>Unexpected Error</h2>',
      ErrorFields: '<h2>Przepraszamy!</h2><p>Proszę wypełnić wszystkie wymagane pola.</p>',
      Thanx: '<h2>Dziękujemy za Twój wniosek!</h2><p>Skontaktujemy się z Tobą wkrótce.</p>',
      NoParams: '<h2>Unsubscribe Error</h2><p>Tough luck! We have tech issues. Please, try again later.</p>',
      NoReason: '<h2>Unsubscribe Error</h2><p>If you don\'t mind, tell us the reason you are leaving, please.</p>',
      NoUser: '<h2>Unsubscribe Error</h2><p>Hmm... It seems to us you have already unsubscribed.</p>',
      Unsubscribed: '<h2>Unsubscribe Success</h2><p>Done. We\'ll be waiting for you to come back!</p>',
      Brochure: '<h2>Broszura jest już w drodze!</h2>'
    },
    es: {
      Unexpected: '<h2>Unexpected Error</h2>',
      ErrorFields: '<h2>¡Lo sentimos!</h2><p>Por favor, es necesario completar todos campos requeridos.</p>',
      Thanx: '<h2>¡Gracias por su solicitud!<p>Nos pondremos en contacto con usted en breve.</p></h2>',
      NoParams: '<h2>Error de cancelación de suscripción</h2><p>¡Mala suerte! Tenemos problemas tecnológicos. Inténtelo de nuevo más tarde.</p>',
      NoReason: '<h2>Error de cancelación de suscripción</h2><p>Si no le importa, díganos el motivo por el que se va, por favor.</p>',
      NoUser: '<h2>Error de cancelación de suscripción</h2><p>Hmm ... Nos parece que ya ha cancelado la suscripción.</p>',
      Unsubscribed: '<h2>Cancelación de suscripción exitosa</h2><p>Nos pondremos en contacto con usted en breve.</p>',
      Brochure: '<h2>¡Gracias! Dentro de poco recibirá el folleto con información</h2>'
    },
    it: {
      Unexpected: '<h2>Errore inaspettato</h2>',
      ErrorFields: '<h2>Ci scusiamo!</h2><p>Compila tutti i campi richiesti.</p>',
      Thanx: '<h2>Grazie per la tua richiesta!</h2><p>Ci metteremo in contatto con te a breve.</p>',
      NoParams: '<h2>Errore di annullamento dell\'iscrizione</h2><p>Sfortuna! Abbiamo problemi tecnici. Per favore riprova più tardi.</p>',
      NoReason: '<h2>Errore di annullamento dell\'iscrizione</h2><p>Se non ti dispiace, dicci il motivo per cui te ne vai, per favore.</p>',
      NoUser: '<h2>Errore di annullamento dell\'iscrizione</h2><p>Hmm... Ci sembra che tu abbia già annullato l\'iscrizione.</p>',
      Unsubscribed: '<h2>Annulla l\'iscrizione con successo</h2><p>Done. We\'ll be waiting for you to come back!</p>',
      Brochure: '<h2>La brochure sta arrivando!</h2>'
    },
    de: {
      Unexpected: '<h2>Unerwarteter Fehler</h2>',
      ErrorFields: '<h2>Entschuldigung!</h2><p>Bitte füllen Sie alle erforderlichen Felder aus.</p>',
      Thanx: '<h2>Vielen Dank für Ihre Anfrage!</h2><p>Wir werden uns in Kürze mit Ihnen in Verbindung setzen.</p>',
      NoParams: '<h2>Abmeldungsfehler</h2><p>Wir haben ein technisches Problem. Bitte versuchen Sie es später noch einmal.</p>',
      NoReason: '<h2>Abmeldungsfehler</h2><p>Wenn es Ihnen nichts ausmacht, sagen Sie uns bitte den Grund für Ihre Abmeldung.</p>',
      NoUser: '<h2>Abmeldungsfehler</h2><p>Hmm... Es scheint, dass Sie sich bereits abgemeldet haben.</p>',
      Unsubscribed: '<h2>Abmeldung erfolgreich</h2><p>Erledigt. Wir greuen uns jedoch, falls Sie sich wieder melden!</p>',
      Brochure: '<h2>Die Broschüre ist auf dem Weg!</h2>'
    },
    id: {
      Unexpected: '<h2>Kesalahan tak terduga</h2>',
      ErrorFields: '<h2>Maaf!</h2><p> Silahkan isi semua kolom yang wajib diisi.</p>',
      Thanx: '<h2>Terima kasih atas permintaan Anda!</h2><p>Kami akan menghubungi Anda segera.</p>',
      NoParams: '<h2>Kesalahan dalam Berhenti Berlangganan.</h2><p>Kesalahan teknis, mohon coba beberapa saat lagi.</p>',
      NoReason: '<h2>Kesalahan dalam Berhenti Berlangganan.</h2><p>Jika Anda tidak keberatan, beri tahu kami alasan Anda berhenti. </p>',
      NoUser: '<h2>Kesalahan Berhenti Berlangganan.</h2><p>Hmm... Tampaknya bagi kami Anda sudah berhenti berlangganan.</p>',
      Unsubscribed: '<h2>Berhenti Berlangganan Berhasil.</h2><p>Kami akan menunggumu kembali!</p>',
      Brochure: '<h2>Brosur sedang dalam perjalanan!</h2>'
    },
    ro: {
      Unexpected: '<h2>Eroare neașteptată</h2>',
      ErrorFields: '<h2>Ne pare rău!</h2><p>Vă rugăm să completați toate câmpurile obligatorii.</p>',
      Thanx: '<h2>Vă mulțumim pentru solicitare!</h2><p>Vă vom contacta în scurt timp.</p>',
      NoParams: '<h2>Eroare la dezabonare.</h2><p>Ghinion! Avem probleme tehnice. Vă rugăm încercați din nou mai târziu.</p>',
      NoReason: '<h2>Eroare la dezabonare.</h2><p>Dacă nu vă deranjează, vă rugăm să ne spuneți motivul pentru care ne părăsiți.</p>',
      NoUser: '<h2>Eroare la dezabonare.</h2><p>Hmm... Se pare că v-ați dezabonat deja.</p>',
      Unsubscribed: '<h2>Dezabonarea s-a efectuat cu succes.</h2><p>Gata. Vă așteptăm să reveniți!</p>',
      Brochure: '<h2>Broșura este pe drum!</h2>'
    }
  }

  const optionsForSearch = 10
  const dropdownHeight = 400
  const select2Options = {
    nosearch: {
      minimumResultsForSearch: Infinity
    },
    search: {
      minimumResultsForSearch: 3
    },
    multiple: {
      minimumResultsForSearch: Infinity,
      allowClear: false,
      closeOnSelect: false,
      scrollAfterSelect: true
    }
  }

  const modalImages = {
    error: '/local/templates/1ci/i/developers/018-112x112.svg',
    success: '/local/templates/1ci/i/developers/001-112x112.svg',
    unsubscribeNoParams: '/local/templates/1ci/i/developers/024.svg',
    unsubscribeNoReason: '/local/templates/1ci/i/developers/025.svg',
    unsubscribeNoUser: '/local/templates/1ci/i/developers/026.svg',
    unsubscribeSuccess: '/local/templates/1ci/i/developers/027.svg'
  }

  const ModalTemplate = `<div class="modal fade error-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <img src="/local/templates/1ci/i/developers/018-112x112.svg" alt="" class="img-fluid modal-image">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/local/templates/1ci/i/icons/Common/009.svg" class="button-icon inline" alt="">
                </button>
            </div>
            <div class="modal-body">
            </div>
        </div>
    </div>
</div>`

  const Paths = {
    FLAGS: '/local/templates/1ci/i/icons/Flag/masks/'
  }

  const PADDINGS = {
    2: '35px',
    3: '43px',
    4: '50px'
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class FormAjax {
    constructor(element) {
      this._element = element
    }

    // Getters

    static get VERSION() {
      return VERSION
    }

    // Public
    formSubmit() {
      const $form = $(this._element)
      const $modal = FormAjax._createModal()
      const currentLang = Util.currentLang()
      const method = $form.attr('method')
      const name = $form.attr('name')
      const action = $form.attr('action') + window.location.search.substr(1)
      const companySize = $form.find(FieldsToGTM.companySize).text()
      const data = $form.serialize()
      const $submitButton = $form.find(Selector.submitButton)
      $submitButton.attr('disabled', true)
      $.ajax({
        type: method,
        url: action,
        data,
        dataType: 'json',
        success(data) {
          const $formsControl = $form.find(Selector.formControl)
          const $modalBody = $modal.find(Selector.modalBody)
          const $modalImage = $modal.find(Selector.modalImage)
          let content = ''
          const gtmEvent = []
          $formsControl.removeClass(Classes.isInvalid)

          if (data === null) {
            FormAjax._GTMEventsPush({
              event: 'unexpected_error',
              formName: name
            })
            FormAjax._imageChangeError($modalImage)
            content = FormErrors[currentLang].Unexpected
          } else if (typeof data.error !== 'undefined') {
            const dataError = data.error
            content = FormErrors[currentLang].ErrorFields
            for (const val in dataError) {
              if ({}.hasOwnProperty.call(dataError, val)) {
                $formsControl.filter(`[name=${val}], [data-name=${val}], [name=${val}_mask]`).addClass(Classes.isInvalid)
                const errorFieldName = dataError[val].split(':')
                if (errorFieldName[1]) {
                  gtmEvent.push(errorFieldName[1].trim())
                }
              }
            }
            FormAjax._GTMEventsPush({
              event: 'form_error',
              fieldName: gtmEvent[0],
              formName: name
            })
            FormAjax._imageChangeError($modalImage)
          } else if (typeof data.note !== 'undefined') {
            if (name === 'get_brochure') {
              content = FormErrors[currentLang].Brochure
            } else {
              content = FormErrors[currentLang].Thanx
            }
            // content += `<p>${data.note}</p>`
            $formsControl.val('')
            const gtmObj = {
              event: 'form_success',
              formName: name
            }
            if (companySize) {
              gtmObj.companySize = companySize
            }
            FormAjax._GTMEventsPush(gtmObj)
            FormAjax._imageChangeSuccess($modalImage)
            $form.trigger(Events.FORM_SUCCESS)
          }
          $modalBody.html(content)
          $modal.modal()
          $submitButton.attr('disabled', false)
        }
      })
    }

    formSwitch() {
      const $sections = $(Selector.formSection)
      const $switchVariants = $(Selector.formsSwitchVariants)
      $switchVariants.toggleClass(Classes.active)
      $sections.each(function () {
        const $this = $(this)
        $this.toggle()
        FormAjax._select2Init($this.find(Selector.inputSelect), false)
        if ($this.css('display') !== 'none') {
          FormAjax._GTMEventsPush({
            event: 'form_switched',
            formName: $this.children('form').attr('name')
          })
        }
      })
    }

    setMaskPhone() {
      const $select = $(this._element)
      const $phoneSelector = $select.find(Selector.phoneMaskSelect)
      const $phonePlaceholder = $select.find(Selector.phoneMaskPlaceholder)
      const $phoneInput = $select.find(Selector.phoneMaskInput)
      const data = $phoneSelector.select2('data')
      let mask
      let index
      if (data.length > 0) {
        mask = data[0].mask || data[0].element.dataset.mask
        index = data[0].index || data[0].element.dataset.index
      } else {
        mask = '(000)000-00-00'
        index = '+000'
      }
      $phonePlaceholder.html(index)
      $phoneInput.val('').mask(mask, {
        placeholder: mask
      }).css('padding-left', () => PADDINGS[index.length])
    }

    setMaskDefault(external = false) {
      const $select = $(this._element)
      let data
      if (external) {
        data = $(Selector.localBlock).attr('data-result')
      } else {
        data = $select.attr('data-result')
      }
      const dataObj = JSON.parse(data)
      if (dataObj.length > 0) {
        const option = new Option(dataObj[0].text, dataObj[0].id, true, true)
        option.dataset.flag = dataObj[0].flag
        option.dataset.index = dataObj[0].index
        option.dataset.mask = dataObj[0].mask
        $select.append(option).trigger({
          type: 'select2:select',
          params: {
            data: dataObj[0]
          }
        })
        const $countrySelector = $(Selector.countryFormSelector)
        if ($countrySelector.length > 0) {
          const $countrySelected = $countrySelector.find('option').filter((i, e) => $(e).text() === dataObj[0].text)
          $countrySelector.val($countrySelected.val()).trigger('change')
        }
      }
    }

    setMaskResult() {
      const $select = $(this._element)
      const $phonePlaceholder = $select.find(Selector.phoneMaskPlaceholder)
      const $phoneResult = $select.find(Selector.phoneMaskResult)
      const $input = $select.find(Selector.phoneMaskInput)
      $phoneResult.val($phonePlaceholder.html() + $input.val())
    }

    dispose() {
      $(this._element).off(EVENT_KEY)
      $.removeData(this._element, DATA_KEY)
      this._element = null
    }

    // Static
    static _createModal() {
      const $modal = $(Selector.errorModal)
      if ($modal.length === 0) {
        const $modalElement = $(ModalTemplate)
        $('main').append($modalElement)
        $modalElement.find(Selector.SVG).trigger(Events.svgReinit)
        return $modalElement
      }
      return $modal
    }

    static _imageChangeError($modalImage) {
      this._imageChange(modalImages.error, $modalImage)
    }

    static _imageChangeSuccess($modalImage) {
      this._imageChange(modalImages.success, $modalImage)
    }

    static _imageChange(link, $modalImage) {
      const imageSrc = $modalImage.attr('src')
      if (imageSrc !== link) {
        $modalImage.attr('src', link)
      }
    }

    static _GTMEventsPush(event) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(event)
    }

    static _select2Init($select, firstInit = true) {
      if ($select) {
        $select.each(function () {
          const $this = $(this)
          const multiple = $this.attr('multiple')
          const url = $this.attr('data-url')
          const placeholder = $this.attr('data-placeholder')
          const isMultiple = multiple === 'multiple'
          const $options = $this.find('option')
          const optionsLength = $options.length
          const id = $this.attr('id')

          if (firstInit && !isMultiple && !placeholder) {
            const $placeholderElement = $options.eq(1)
            const placeholderText = $placeholderElement.text()
            $options.first().attr('value', '')
            $placeholderElement.remove()
            $this.attr('data-placeholder', placeholderText)
          }

          // eslint-disable-next-line no-nested-ternary
          const params = Object.assign({}, isMultiple ? select2Options.multiple : optionsLength > optionsForSearch ? select2Options.search : select2Options.nosearch)

          if (typeof url !== 'undefined' && url.length > 0) {
            $.ajax({
              type: 'get',
              url,
              dataType: 'json',
              success(data) {
                $this.attr('data-result', JSON.stringify(data))
                params.data = data
                $this.select2(params).trigger(Events.selectInited)
              }
            })
          } else {
            if (id === 'sections') {
              params.templateResult = FormAjax._templateResultFilterMultiple
              params.templateSelection = () => false
              params.width = '100%'
            }
            if (id === 'country') {
              params.templateResult = FormAjax._templateResultFilter
              params.templateSelection = () => placeholder
              params.width = '100%'
            }
            if ($this.hasClass(Classes.reset)) {
              params.templateSelection = FormAjax._templateResultFilterReset
              // params.allowClear = true
              params.width = '100%'
            }
            $this.select2(params).trigger(Events.selectInited)
          }
          if (Util.getViewport() === 'xs') {
            $this.on('select2:open select2:close', () => {
              $(Selector.searchField).prop('focus', false).blur()
            })
          }
        })
      }
    }

    static _scrollSelector() {
      const $viewport = $(Selector.phoneMaskResults)
      const searchHeight = $(Selector.phoneMaskSearchDropdown).outerHeight()
      const viewportScroll = $viewport.scrollTop()
      const $selectedItem = $viewport.find(Selector.phoneMaskSelectedOption)
      if ($selectedItem.length > 0) {
        const itemTop = $selectedItem.position().top
        $viewport.scrollTop(itemTop + viewportScroll - searchHeight)
      }
    }

    static _templateResultFilter(state) {
      if (!state.id) {
        return state.text
      }
      const out = `<div class="custom-control custom-checkbox"><input type="checkbox" class="form-check-input custom-control-input custom-control-input--filter__countries" id="${state.id}" name="form_checkbox_filter" value="${state.id}"><label for="${state.id}" data-id="${state.id}" class="custom-control-label--filter__countries">${state.text}</label></div>`
      return $(out)
    }

    static _templateResultFilterMultiple(state) {
      if (!state.id) {
        return state.text
      }
      const out = `<div class="custom-control custom-checkbox"><input type="checkbox" class="form-check-input custom-control-input custom-control-input--filter__categories" id="${state.id}" name="form_checkbox_filter" value="${state.id}"><label data-id="${state.id}" class="custom-control-label custom-control-label--filter__categories"></label>${state.text}</div>`
      return $(out)
    }

    static _templateResultFilterReset(state) {
      if (!state.id) {
        return state.text
      }
      const out = `<span>${state.text}<span class="filter__delete"></span></span>`
      return $(out)
    }

    static _templateSelectPhoneMask(state) {
      if (!state.id) {
        return state.text
      }
      const flag = state.flag || state.element.dataset.flag
      const index = state.index || state.element.dataset.index
      const mask = state.index || state.element.dataset.mask
      const out = `<span class="country" data-mask="${mask}"><img src="${Paths.FLAGS}${flag}.svg" class="country_flag" alt="${state.text}"><p class="country_text">${state.text}</p><p class="country_index">${index}</p></span>`
      return $(out)
    }

    static _jQueryInterface(config, ...params) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data && /destroy|hide/.test(config)) {
          return
        }

        if (!data) {
          data = new FormAjax(this, _config)
          $(this).data(DATA_KEY, data)
        }
        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config](...params)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).ready(() => {
    /**
     * ------------------------------------------------------------------------
     * submit all AJAX forms
     * ------------------------------------------------------------------------
     */
    const $formAjax = $(Selector.formAjax)
    if ($formAjax.length > 0) {
      $formAjax.on('submit', (e) => {
        e.preventDefault()
        FormAjax._jQueryInterface.call($(e.target), 'formSubmit')
      })
    }
    /**
     * ------------------------------------------------------------------------
     * init Select2 inputs
     * ------------------------------------------------------------------------
     */
    const $select = $(Selector.inputSelect)
    if ($select.length > 0) {
      FormAjax._select2Init($select)
      $(window).resize(() => FormAjax._select2Init($select, false))
      $(document).on(Events.LOCAL_BLOCK_SHOWED, () => FormAjax._select2Init($select, false))
      const $resetFilters = $(Selector.resetFilterSelect)
      if ($resetFilters.length > 0) {
        $resetFilters.on(Events.SELECT_SELECTED, (e) => {
          const $selector = $(e.target)
          const id = $selector.attr('id')
          const $selectorContainer = $(`#select2-${id}-container`)
          const $resetBtn = $selectorContainer.find(Selector.resetFilter)
          // $selector.on('select2:unselect', function () {
          //   $(this).select2('close')
          // })
          $resetBtn.off().on(Events.click, () => {
            $selector.val('').trigger(Events.change)
          })
          $selector.prop('focus', false).blur()
        })
      }
    }
    /**
     * ------------------------------------------------------------------------
     * init custom checkboxes and data attrs
     * ------------------------------------------------------------------------
     */
    const $checkboxes = $(Selector.checkboxes)
    if ($checkboxes.length > 0) {
      $checkboxes.each(function () {
        const $this = $(this)
        const id = this.id
        $this.addClass(Classes.customControlInput).next('label').addClass(Classes.customControlLabel)
          .attr('data-name', `form_checkbox_${id}`)
      })
    }
    /**
     * ------------------------------------------------------------------------
     * add preselect for selectors
     * ------------------------------------------------------------------------
     */
    const $attrs = $(Selector.attrs)
    if ($attrs.length > 0) {
      const sourceSelector = $attrs.attr('data-source')
      const selectedSelector = $attrs.attr('data-selected')
      if (selectedSelector && sourceSelector) {
        const sourceSelectorArr = sourceSelector.split(',')
        const selectedSelectorArr = selectedSelector.split(',')
        if (sourceSelectorArr.length === selectedSelectorArr.length) {
          for (let i = 0; i < sourceSelectorArr.length; i++) {
            const $selectorForSelect = $(`select[name='${sourceSelectorArr[i]}']`)
            if ($selectorForSelect.length === 1) {
              $selectorForSelect.val(selectedSelectorArr[i]).trigger('change')
            }
            if ($selectorForSelect.length > 1) {
              $selectorForSelect.eq(i).val(selectedSelectorArr[i]).trigger('change')
            }
          }
        }
      }
    }
    /**
     * ------------------------------------------------------------------------
     * switch 2 form on block
     * ------------------------------------------------------------------------
     */
    const $formSwitch = $(Selector.formSwitch)
    const $formLabels = $(Selector.formsSwitchVariants)
    if ($formSwitch.length > 0) {
      $formSwitch.on('change', (e) => {
        e.preventDefault()
        FormAjax._jQueryInterface.call($(e.target), 'formSwitch')
      })
    }
    if ($formLabels.length > 0) {
      $formLabels.on('click', () => {
        $formSwitch.trigger('click')
      })
    }
    /**
     * ------------------------------------------------------------------------
     * Phone Mask
     * ------------------------------------------------------------------------
     */
    const $phoneContainer = $(Selector.phoneMaskContainer)
    if ($phoneContainer.length > 0) {
      $phoneContainer.each((i, e) => {
        const $selector = $(e)
        const $phoneSelector = $selector.find(Selector.phoneMaskSelect)
        const $phoneInput = $selector.find(Selector.phoneMaskInput)
        if ($phoneSelector.length > 0 && $phoneInput.length > 0) {
          $phoneSelector.select2({
            width: '64px',
            ajax: {
              url: `/api/v1/filter/phonemask?lang=${Util.currentLang()}`,
              dataType: 'json',
              cache: true
            },
            dropdownParent: $selector,
            templateSelection: FormAjax._templateSelectPhoneMask,
            templateResult: FormAjax._templateSelectPhoneMask
          }).on(Events.SELECT_OPENING, (e) => {
            const elementTop = parseInt($phoneSelector.offset().top, 10)
            const elementBottom = elementTop + dropdownHeight
            const viewportTop = $(window).scrollTop()
            const viewportBottom = viewportTop + $(window).height()
            const scroll = viewportTop + (elementBottom - viewportBottom)
            if (elementBottom > viewportBottom) {
              e.preventDefault()
              $('html, body').stop().animate({
                scrollTop: scroll
              }, '500', () => {
                $phoneSelector.select2('open')
              })
            }
          }).on(Events.SELECT_LOADED, () => {
            FormAjax._scrollSelector()
          }).change(() => {
            FormAjax._jQueryInterface.call($selector, 'setMaskPhone')
            FormAjax._jQueryInterface.call($selector, 'setMaskResult')
          })
          FormAjax._jQueryInterface.call($phoneSelector, 'setMaskDefault')
          FormAjax._jQueryInterface.call($selector, 'setMaskPhone')
          $(document).on(Events.LOCAL_BLOCK_SHOWED, () => {
            FormAjax._jQueryInterface.call($phoneSelector, 'setMaskDefault', true)
            FormAjax._jQueryInterface.call($selector, 'setMaskPhone')
          })
          $phoneInput.on('input', () => {
            FormAjax._jQueryInterface.call($selector, 'setMaskResult')
          })
        }
      })
    }
    /**
     * ------------------------------------------------------------------------
     * Unsubscribe for GetResponse form
     * ------------------------------------------------------------------------
     */
    const $formUnsubscribe = $(Selector.unsubscribe)
    if ($formUnsubscribe.length > 0) {
      const currentLang = Util.currentLang()
      const $unsubscribeButton = $(Selector.unsubscribeButton)
      $unsubscribeButton.on(Events.click, (e) => {
        e.preventDefault()
        const $modal = $(Selector.unsubscribeErrorModal)
        const $modalBody = $modal.find(Selector.modalBody)
        const $modalImage = $modal.find(Selector.modalImage)
        const $reason = $formUnsubscribe.find(Selector.unsubscribeReason)
        if ($reason.length > 0) {
          $unsubscribeButton.attr('disabled', true)
          const reasonVal = $reason.val()
          const uriParams = Util.getURLParameters()
          if (reasonVal && {}.hasOwnProperty.call(uriParams, 'id') && {}.hasOwnProperty.call(uriParams, 'message')) {
            $.get('/api/v1/gr/unsubscribe', {
              id: uriParams.id,
              message: uriParams.message,
              reason: reasonVal
            }).done(() => {
              FormAjax._imageChange(modalImages.unsubscribeSuccess, $modalImage)
              $modalBody.html(FormErrors[currentLang].Unsubscribed)
              $modal.modal()
              $unsubscribeButton.attr('disabled', false)
            }).fail((data) => {
              // eslint-disable-next-line no-magic-numbers
              if (data.status === 404) {
                $modalBody.html(FormErrors[currentLang].NoUser)
                FormAjax._imageChange(modalImages.unsubscribeNoUser, $modalImage)
              } else {
                $modalBody.html(FormErrors[currentLang].Unexpected)
              }
              $modal.modal()
              $unsubscribeButton.attr('disabled', false)
            })
          } else {
            FormAjax._imageChange(modalImages.unsubscribeNoParams, $modalImage)
            $modalBody.html(FormErrors[currentLang].NoParams)
            $modal.modal()
            $unsubscribeButton.attr('disabled', false)
          }
        } else {
          FormAjax._imageChange(modalImages.unsubscribeNoReason, $modalImage)
          $modalBody.html(FormErrors[currentLang].NoReason)
          $modal.modal()
          $unsubscribeButton.attr('disabled', false)
        }
      })
    }
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = FormAjax._jQueryInterface
  $.fn[NAME].Constructor = FormAjax
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return FormAjax._jQueryInterface
  }

  return FormAjax
})($)

export default FormAjax
