import $ from 'jquery'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): util.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Util = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Private TransitionEnd Helpers
   * ------------------------------------------------------------------------
   */

  const TRANSITION_END = 'transitionend'
  const MAX_UID = 1000000
  const MILLISECONDS_MULTIPLIER = 1000
  const BOOTSTRAP_GRID = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200
  }

  // Shoutout AngusCroll (https://goo.gl/pxwQGp)
  function toType(obj) {
    return {}.toString.call(obj).match(/\s([a-z]+)/i)[1].toLowerCase()
  }

  function getSpecialTransitionEndEvent() {
    return {
      bindType: TRANSITION_END,
      delegateType: TRANSITION_END,
      handle(event) {
        if ($(event.target).is(this)) {
          return event.handleObj.handler.apply(this, arguments) // eslint-disable-line prefer-rest-params
        }
        return undefined // eslint-disable-line no-undefined
      }
    }
  }

  function transitionEndEmulator(duration) {
    let called = false

    $(this).one(Util.TRANSITION_END, () => {
      called = true
    })

    setTimeout(() => {
      if (!called) {
        Util.triggerTransitionEnd(this)
      }
    }, duration)

    return this
  }

  function setTransitionEndSupport() {
    $.fn.emulateTransitionEnd = transitionEndEmulator
    $.event.special[Util.TRANSITION_END] = getSpecialTransitionEndEvent()
  }

  /**
   * --------------------------------------------------------------------------
   * Public Util Api
   * --------------------------------------------------------------------------
   */

  const Util = {

    TRANSITION_END: 'bsTransitionEnd',
    EDIT_SELECTOR: '.landing-edit-mode',

    getUID(prefix) {
      do {
        // eslint-disable-next-line no-bitwise
        prefix += ~~(Math.random() * MAX_UID) // "~~" acts like a faster Math.floor() here
      } while (document.getElementById(prefix))
      return prefix
    },

    getSelectorFromElement(element) {
      let selector = element.getAttribute('data-target')
      if (!selector || selector === '#') {
        selector = element.getAttribute('href') || ''
      }

      try {
        return document.querySelector(selector) ? selector : null
      } catch (err) {
        return null
      }
    },

    getTransitionDurationFromElement(element) {
      if (!element) {
        return 0
      }

      // Get transition-duration of the element
      let transitionDuration = $(element).css('transition-duration')
      const floatTransitionDuration = parseFloat(transitionDuration)

      // Return 0 if element or transition duration is not found
      if (!floatTransitionDuration) {
        return 0
      }

      // If multiple durations are defined, take the first
      transitionDuration = transitionDuration.split(',')[0]

      return parseFloat(transitionDuration) * MILLISECONDS_MULTIPLIER
    },

    reflow(element) {
      return element.offsetHeight
    },

    triggerTransitionEnd(element) {
      $(element).trigger(TRANSITION_END)
    },

    // TODO: Remove in v5
    supportsTransitionEnd() {
      return Boolean(TRANSITION_END)
    },

    isElement(obj) {
      return (obj[0] || obj).nodeType
    },

    typeCheckConfig(componentName, config, configTypes) {
      for (const property in configTypes) {
        if (Object.prototype.hasOwnProperty.call(configTypes, property)) {
          const expectedTypes = configTypes[property]
          const value = config[property]
          const valueType = value && Util.isElement(value) ? 'element' : toType(value)

          if (!new RegExp(expectedTypes).test(valueType)) {
            throw new Error(`${componentName.toUpperCase()}: Option "${property}" provided type "${valueType}" but expected type "${expectedTypes}".`)
          }
        }
      }
    },

    getCookie(name) {
      const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`))
      return matches ? decodeURIComponent(matches[1]) : false
    },

    setCookie(name, value, options = {}) {
      options = {
        path: '/',
        ...options
      }
      let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
      for (const optionKey in options) {
        if ({}.hasOwnProperty.call(options, optionKey)) {
          updatedCookie += `; ${optionKey}`
          const optionValue = options[optionKey]
          if (optionValue !== true) {
            updatedCookie += `=${optionValue}`
          }
        }
      }
      document.cookie = updatedCookie
    },

    deleteCookie(name) {
      this.setCookie(name, '', {
        'max-age': -1
      })
    },

    isEditMode() {
      try {
        return Boolean(document.querySelector(this.EDIT_SELECTOR))
      } catch (err) {
        return null
      }
    },

    currentLang() {
      try {
        return $('html')
          .attr('lang')
      } catch (err) {
        return 'en'
      }
    },

    getViewport() {
      const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      if (width <= BOOTSTRAP_GRID.xs) {
        return 'xs'
      }
      if (width <= BOOTSTRAP_GRID.sm) {
        return 'sm'
      }
      if (width <= BOOTSTRAP_GRID.md) {
        return 'md'
      }
      if (width <= BOOTSTRAP_GRID.lg) {
        return 'lg'
      }
      return 'xl'
    },

    isMobile() {
      return this.getViewport() === 'xs' || this.getViewport() === 'sm'
    },

    isTablet() {
      return this.getViewport() === 'md'
    },

    isDesktop() {
      return this.getViewport() === 'lg' || this.getViewport() === 'xl'
    },

    getURLParameters() {
      const sPageURL = window.location.search.substring(1)
      const sURLVariables = sPageURL.split('&')
      const vars = {}
      for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=')
        const key = sParameterName[0]
        if (/\[[0-9]*\]/.test(key) === true) {
          const pkey = key.split(/\[[0-9]*\]/)[0]
          if (typeof vars[pkey] === 'undefined') {
            vars[pkey] = []
          }
          vars[pkey].push(decodeURIComponent(sParameterName[1] || ''))
        } else {
          vars[key] = decodeURIComponent(sParameterName[1] || '')
        }
      }
      return vars
    },

    setURLParameters(varsObj) {
      $.each(varsObj, (name, params) => {
        if (params.length === 0) {
          varsObj[name] = ''
        }
      })
      const varsStr = decodeURIComponent($.param(varsObj))
      const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      if (history) {
        history.pushState(varsObj, 'false', `${url}?${varsStr}`)
      }
    },

    intersection(o1, a2) {
      if (a2.length === 0) {
        return o1
      }
      const r = []
      o1.map((i, item1) => {
        a2.map((item2) => {
          if (item1 === item2) {
            r.push(item2)
          }
          return false
        })
        return false
      })
      return r
    },

    removeItem(arr, value) {
      const index = arr.indexOf(value)
      if (index > -1) {
        arr.splice(index, 1)
      }
      return arr
    },

    scrollToSelector($selector) {
      const offset = $selector.offset()
      $('html, body').stop().animate({
        scrollTop: offset.top
      }, '500')
    }

  }

  setTransitionEndSupport()

  return Util
})($)

export default Util
