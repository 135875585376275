import $ from 'jquery'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): parallax.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Parallax = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'parallax'
  const VERSION = '4.1.3'
  const DATA_KEY = 'bs.parallax'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Selector = {
    PARALLAX: '.parallax'
  }

  const CSSProps = {
    BGATTACH: 'background-attachment'
  }

  const Settings = {
    position: 'center',
    speed: 0.15
  }


  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Parallax {
    constructor(element) {
      this._element = element
      this.windowHeight = $(window).height()
    }

    // Getters

    static get VERSION() {
      return VERSION
    }

    // Public

    parallax() {
      const $this = $(this._element)
      if ($this.css(CSSProps.BGATTACH) === 'scroll') {
        return
      }
      const settings = {
        speed: $this.data('speed') ? $this.data('speed') : Settings.speed,
        position: $this.data('position') ? $this.data('position') : Settings.position
      }
      $(document).on('scroll', function () {
        const scrollTop = $(window).scrollTop()
        const offset = $this.offset().top
        const height = $this.outerHeight()
        if (offset + height <= scrollTop || offset >= scrollTop + this.windowHeight) {
          return
        }
        const yBgPosition = Math.round((offset - scrollTop) * settings.speed)
        $this.css('background-position', `${settings.position} ${yBgPosition}px`)
        $this.trigger(EVENT_KEY)
      })
    }

    dispose() {
      $(this._element).off(EVENT_KEY)
      $.removeData(this._element, DATA_KEY)
      this._element = null
      this.windowHeight = null
    }

    // Static

    static _jQueryInterface(config) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data && /destroy|hide/.test(config)) {
          return
        }

        if (!data) {
          data = new Parallax(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config]()
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).ready(() => {
    const $parallax = $(Selector.PARALLAX)
    if ($parallax.length > 0) {
      Parallax._jQueryInterface.call($parallax, 'parallax')
    }
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Parallax._jQueryInterface
  $.fn[NAME].Constructor = Parallax
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Parallax._jQueryInterface
  }

  return Parallax
})($)

export default Parallax
